const AttributeContentMap: { [key: string]: string } = {
    'displayname': 'Display Name',
    'givenname': 'First Name',
    'surname': 'Last Name',
    'email': 'Email',
    'mail': 'Email',
    'name': 'First Name',
    'e-mail': 'Email',
    'first name': 'First Name',
    'last name': 'Last Name',
};

export function getAttributeContent(attributeName: string): string {
    console.log('getAttributeContent: ' + attributeName);
    let lowerCaseAttributeName = attributeName.toLowerCase();
    console.log('getAttributeContent: ' + lowerCaseAttributeName);
    return AttributeContentMap[lowerCaseAttributeName];
}


