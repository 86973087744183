import http  from 'axios';

export const AttributeChangeHeaderStatus: Record<number, string> = {
    100 : "Requested",
    200 : "Viewed",
    300 : "Executing",
    400 : "Completed",
    500 : "Expired",
    600 : "Cancelled",
    700 : "Error",
    800 : "Rejected",
} as const;

export interface AttributeChangeHeader {
    id: string;
    systemId: string;
    userId: string;
    status: number;
    notificationType: string;
    notificationValue: string;
    notificationSentTime: string;
    transactionId: string;
    createdDatetime: string;
    createdBy: string;
    updatedDatetime: string;
    updatedBy: string;
}

export interface AttributeChangeLine {
    id: string;
    attributeChangeHeaderId: string;
    attributeName: string;
    oldValue: string;
    newValue: string;
    status: number;
    transactionId: string;
    createdDatetime: string;
    createdBy: string;
}

export interface B2CTransaction {
    attributeChangeHeader: AttributeChangeHeader;
    attributeChangeDetails: AttributeChangeLine[];
}

export interface TransactionsResult {
    data: B2CTransaction[];
    count: number;
    page: number;
}

export interface TransactionViewed {
    id: string;
    status: number;
}

const getTransactions = async (page = 0, size = 10) : Promise<TransactionsResult> => {
    let url = `${process.env.REACT_APP_API_URL}/transactions?page=${page}&size=${size}`;

    const result = (await http.get<TransactionsResult>(url)).data;
    return result;
}

const getTransaction = async (transcationId: string) => {
    let url = `${process.env.REACT_APP_API_URL}/transactions/${transcationId}`;

    return await http.get<B2CTransaction>(url);
}

const updateTransactionStatus = async (transactionId: string, accept: boolean) => {
    let url = `${process.env.REACT_APP_API_URL}/transactions/${transactionId}?Accept=${accept? 'yes': 'no'}`;

    try {
        const res = await http.post(url);
        return res.status;
    } catch (err) {
        console.log(err);
        return null;
    }
}

const setViewed = async (transactionId: string) => {
    let url = `${process.env.REACT_APP_API_URL}/transactions/${transactionId}}`;

    try {
        let data = { id: transactionId, status: 200 };
        const res = await http.put(url, data);
        return res.status;
    } catch (err) {
        console.log(err);
        return null;
    }
}

const TransactionService = {
    getTransaction, 
    updateTransactionStatus,
    getTransactions,
    setViewed
}

export default TransactionService;