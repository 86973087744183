import "./Bar.css";
import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import { PageNames } from "../../App";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { useAccount, useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from '@azure/msal-react';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  '@media all': {
    minHeight: 100,
  },
}));

function stringAvatar(name: string | undefined) {
  if(!name || name.trim() === '') {
    return '';
  }
  
  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1];
  
  if (!firstName || firstName.trim() === '') {
    return '';
  }
  
  if (!lastName || lastName.trim() === '') {
    return {
      children: `${firstName[0]}`,
    };
  }

  return {
    children: `${firstName[0]}${lastName[0]}`,
  };
}

export default function Bar() {
  let msal = useMsal();
  let activeAccount = msal.instance.getActiveAccount();
  let location = useLocation();
  const [pageName,setPageName] = React.useState<string | undefined>('Home');
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {

    const logoutRequest = {
      account: activeAccount,
    };
    msal.instance.logoutRedirect(logoutRequest);
  };

  React.useEffect(() => {
    const pageName = PageNames.get(location.pathname);
    document.title = `ICON plc | ${pageName}`
    setPageName(pageName);
  }, [location]);

  const isAuthenticated = useIsAuthenticated();
  const user = useAccount();
  
  return (
    <AppBar position="absolute">
      {/* <Container maxWidth=""> */}
        <StyledToolbar>
        
        {/* edge="start" disableRipple={true} */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <img src="/logo.svg" className="logo-bar" alt="ICON logo" />
          </Typography>
          
          {/* Mobile View */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              
              {Array.from(PageNames).map((page) => {
              let pageName = page[1];
              let path = page[0];
               return (<MenuItem  key={pageName} onClick={handleCloseNavMenu} 
                component={Link} to={path} selected={path === location.pathname}  >
                  <Typography textAlign="center">{pageName}</Typography>
                </MenuItem>)
              })}

            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1
            }}
          >
            <img src="/logo.svg" className="logo-bar" alt="ICON logo" />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            
            {Array.from(PageNames).map((page) => {
              let pageName = page[1];
              let path = page[0];
               return (<MenuItem  key={pageName} onClick={handleCloseNavMenu} sx={{ my: 2, color: 'white', display: 'block' }} 
                component={Link} to={path} selected={path === location.pathname}  >
                  {pageName}
                </MenuItem>)
              })}
          </Box>
          {/* Profile */}
          {isAuthenticated && user? <Box sx={{ flexGrow: 0 }}>
            
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user.name} {...stringAvatar(user.name)}/>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              
              <MenuItem key="Logout" onClick={logout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
              
            </Menu>
          </Box> : '' }
          

        </StyledToolbar>
      {/* </Container> */}
    </AppBar>
  );
}
